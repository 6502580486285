<template>
    <div
        id="app"
        :class="$style.app"
    >
        <router-view />
    </div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue';
import { mapMutations } from 'vuex';
import axios from 'axios';
import store from '@/stores/store';

export default {
    name: 'App',
    store,
    created() {
        this.$setMainStyle(this.$style);
    },
};
</script>

<style lang="scss" module>
    @import '@/assets/css/main.scss';
    .app {
        height: 100%;
        font-size: 14px;

        & > :global(.container) {
            min-height: 100%;
        }
    }
</style>
