<template>
    <div class="safeteeview fullscreen homeconnect">
        <header class="header">
            <router-link tag="a" to="/" class="safetee-logo">
                <MasterSvg class="logo" use="logo" />
                <MasterSvg class="logo-secondary" use="logo-secondary" />
            </router-link>
            <div class="header__links">
                <a class="buttoncomp" href="https://dashboard.safetee.io/">
                    {{ $t("home.Connect") }}
                </a>
            </div>
        </header>

        <section
            class="head-section"
            data-aos="fade-up"
            data-aos-duration="1500"
        >
            <div
                class="section_content w-100 d-flex flex-row row align-items-center"
            >
                <div class="head-section__text col-12 col-md-6">
                    <h1 class="head-section__title">
                        La gestion de votre logistique dans le cloud.
                    </h1>
                    <p
                        class="head-section__description text-sm-left text-justify"
                    >
                        Safetee vous apporte une solution de WMS (Warehouse
                        Management System) en ligne, activable en quelques
                        minutes, vous permettant de gérer efficacement la
                        logistique de votre entrepôt.

                        Aucun frais d'installation.
                    </p>
                    <div class="head-section__buttons">
                        <a
                            class="buttoncomp"
                            href="https://dashboard.safetee.io/register/"
                        >
                            Essayer Safetee
                        </a>
                        <a
                            href="#"
                            class="moreinfo-button buttoncomp secondary ghost"
                            @click="scroll"
                        >
                            Plus d'informations
                        </a>
                    </div>
                </div>
                <MasterSvg class="illustration col-12 col-md-6" use="illu-01" />
            </div>
        </section>

        <RegisterSection />

        <section
            class="mission-section section--blue section"
            ref="mission"
            data-aos="fade-up"
            data-aos-duration="1500"
        >
            <div
                class="section_content w-100 d-flex d-sm-flex flex-column d-sm-block"
            >
                <h2 class="section__title h1 underline">Notre mission</h2>
                <div class="row">
                    <div class="col">
                        <div class="homesubtitle mission-section__subtitle">
                            Vous aider à vous concentrer sur votre métier. Nous
                            nous occupons du reste.
                        </div>
                    </div>
                </div>
                <div
                    class="row mission-section__cards-list justify-content-center"
                >
                    <div class="col-12 col-sm-6 my-2">
                        <div class="mission-section__card">
                            <MasterSvg
                                class="mission-section__card-icon"
                                use="tools"
                            />
                            <h4 class="bold mission-section__card-title">
                                Des outils pour votre logistique
                            </h4>
                            <p class="text-sm-left text-justify">
                                Safetee vous apporte les outils dont vous avez
                                besoin pour gérer vos receptions, retraits et envois de marchandises ainsi que
                                votre stock.
                            </p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 my-2">
                        <div class="mission-section__card">
                            <MasterSvg
                                class="mission-section__card-icon"
                                use="interface"
                            />
                            <h4 class="bold mission-section__card-title">
                                Une interface complète pour vos clients
                            </h4>
                            <p class="text-sm-left text-justify">
                                L'accès à l'interface est inclu pour vos clients qui pourront
                                créer eux mêmes leurs fournisseurs, leurs produits,
                                leurs réceptions et leurs livraisons de marchandises.
                            </p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 my-2">
                        <div class="mission-section__card">
                            <MasterSvg
                                class="mission-section__card-icon"
                                use="document"
                            />
                            <h4 class="bold mission-section__card-title">
                                Votre facturation en temps réel
                            </h4>
                            <p class="text-sm-left text-justify">
                                Définissez vos prix de livraisons, réceptions,
                                stock, services et produits pour chacun de vos
                                clients et suivez l'évolution de votre
                                facturation.
                            </p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 my-2">
                        <div class="mission-section__card">
                            <MasterSvg
                                class="mission-section__card-icon"
                                use="cloud"
                            />
                            <h4 class="bold mission-section__card-title">
                                Dans le cloud, accessible 24/24
                            </h4>
                            <p class="text-sm-left text-justify">
                                Profitez des avantages d'une solution infogérée.
                                Safetee est accessible 24/24 depuis tous vos
                                terminaux. Vous bénéficiez d'évolutions et mises
                                à jour régulières, sans impact pour votre
                                activité.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="features-section section">
            <div class="halfcircle" v-show="!isMobile" />
            <div
                class="section_content w-100 d-flex d-sm-flex flex-column d-sm-block"
            >
                <h2 class="section__title h1 underline">
                    Les fonctionnalités de Safetee.io
                </h2>
                <p class="homesubtitle text-sm-left text-justify mx-sm-auto">
                    Des outils intégrés et simples
                </p>

                <div
                    class="section-step"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-duration="1500"
                >
                    <div class="section-step__content">
                        <div class="row">
                            <div
                                class="col-12 col-md-6 align-items-left justify-content-center d-flex flex-column"
                            >
                                <h3>Des outils pour votre logistique</h3>
                                <p class="mt-4 mb-4 text-sm-left text-justify">
                                    Une suite d'outils à votre disposition pour
                                    opérer votre métier de logisticien.
                                </p>
                                <ul class="feature-list">
                                    <li class="feature-list__item" icon="📦">
                                        <div class="feature-list-item__title">
                                            Gestion des préparations
                                        </div>
                                        <div class="feature-list-item__text">
                                            Accédez aux réceptions, préparations
                                            et livraisons de marchandises à
                                            effectuer.
                                        </div>
                                    </li>

                                    <li class="feature-list__item" icon="📑">
                                        <div class="feature-list-item__title">
                                            Impression PDF
                                        </div>
                                        <div class="feature-list-item__text">
                                            Imprimez les formulaires de manutention ou effectuez les
                                            opérations directement sur la plateforme.
                                        </div>
                                    </li>

                                    <li class="feature-list__item" icon="🔍">
                                        <div class="feature-list-item__title">
                                            Stock
                                        </div>
                                        <div class="feature-list-item__text">
                                            Consultez et exportez l'état de votre
                                            stock. Imprimez des étiquettes pour
                                            vos rayonnages et palettes.
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 col-md-6">
                                <MasterSvg class="illustration" use="illu-02" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section-step">
                    <div class="section-step__content">
                        <div
                            class="row"
                            data-aos="fade-up"
                            data-aos-duration="1500"
                        >
                            <div
                                class="col-12 col-md-6 align-items-center d-flex"
                            >
                                <MasterSvg class="illustration" use="illu-03" />
                            </div>
                            <div class="col-12 col-md-6">
                                <h3>Une interface pour vos clients</h3>
                                <p class="mt-4 text-sm-left text-justify">
                                    Gérez les accès à Safetee pour vos clients.
                                    <!--<span class="bold">sereinement</span> et en toute <span class="bold">sécurité</span> avec ses collaborateurs.-->
                                </p>
                                <ul class="feature-list mt-4">
                                    <li class="feature-list__item" icon="💻">
                                        <div class="feature-list-item__title">
                                            Produits et fournisseurs
                                        </div>
                                        <div class="feature-list-item__text">
                                            Vos clients sont autonomes dans la
                                            gestion de leurs fournisseurs,
                                            produits, réceptions et livraisons.
                                        </div>
                                    </li>

                                    <li class="feature-list__item" icon="💬">
                                        <div class="feature-list-item__title">
                                            Notifications
                                        </div>
                                        <div class="feature-list-item__text">
                                            Des notifications leurs sont envoyées
                                            en temps réel à chaque étape.
                                        </div>
                                    </li>

                                    <li class="feature-list__item" icon="💪">
                                        <div class="feature-list-item__title">
                                            Comptes de démonstration
                                        </div>
                                        <div class="feature-list-item__text">
                                            Créez facilement des comptes de
                                            démonstration pour convaincre vos
                                            futurs clients de vous rejoindre.
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="section-step"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                >
                    <div class="section-step__content">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <h3>Facturation intégrée</h3>
                                <p class="mt-4 text-sm-left text-justify">
                                    Facturez vos clients facilement grace à
                                    notre facturation
                                    <span class="bold">intégrée</span> et
                                    <span class="bold">automatique</span>.
                                </p>
                                <ul class="feature-list mt-4">
                                    <li class="feature-list__item" icon="📗">
                                        <div class="feature-list-item__title">
                                            Catalogues personnalisables
                                        </div>
                                        <div class="feature-list-item__text">
                                            Définissez un ou plusieurs catalogues
                                            et assignez les à vos clients pour
                                            une facturation personnalisée.
                                        </div>
                                    </li>

                                    <li class="feature-list__item" icon="💵">
                                        <div class="feature-list-item__title">
                                            Facturation en temps réel
                                        </div>
                                        <div class="feature-list-item__text">
                                            Les factures de vos clients sont
                                            mises à jour automatiquement en
                                            temps réel.
                                        </div>
                                    </li>

                                    <li class="feature-list__item" icon="💡">
                                        <div class="feature-list-item__title">
                                            Algorithmes de prix
                                        </div>
                                        <div class="feature-list-item__text">
                                            Vos prix peuvent dépendre de
                                            nombreux critères (quantité,
                                            forfait, distance, etc..). Créez des
                                            algorithmes personnalisés qui seront
                                            pris en compte pour chaque ligne de
                                            facture.

                                            Si besoin, Safetee s'occupera pour vous de la création de ces algorithmes.
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div
                                class="col-12 col-md-6 align-items-center d-flex"
                            >
                                <MasterSvg class="illustration" use="illu-04" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <RegisterSection />

        <footer class="d-block d-sm-flex text-center text-sm-left">
            <MasterSvg use="logo-negative" />
            <nav>
                <ul class="d-block d-sm-flex">
                    <!--<li class="my-3 my-sm-0 mx-0 mx-sm-4">
                        <a href="https://dashboard.safetee.io/register/">
                            Conditions générales
                        </a>
                    </li>-->
                    <li class="my-3 my-sm-0 mx-0 mx-sm-4"
                        @click="legalClicked">
                        <router-link
                            tag="a"
                            href="#"
                            to="#"
                        >
                            Mentions légales
                        </router-link>
                    </li>
                    <li class="my-3 my-sm-0 mx-0 mx-sm-4"
                        @click="contactClicked">
                        <router-link
                            tag="a"
                            href="#"
                            to="#"
                        >
                            Nous contacter
                        </router-link>
                    </li>
                    <li class="my-3 my-sm-0 mx-0 mx-sm-4">
                        Illustrations du site par
                        <a href="https://stories.freepik.com/people">Freepik Stories</a>
                    </li>
                </ul>
            </nav>
        </footer>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import MasterSvg from "@/components/master-svg.vue";
import RegisterSection from "@/components/register.vue";
import AOS from "aos";
import { isMobile } from "mobile-device-detect";
import axios from 'axios';

export default {
    components: {
        MasterSvg,
        RegisterSection,
    },
    data() {
        return { isMobile };
    },
    methods: Object.assign(mapActions("session", ["signIn"]), {
        scroll() {
            var element = this.$refs.mission;
            this.$scrollTo(element, 1000, { container: ".safeteeview" });
        },
        legalClicked() {

            let swal = this.$swal({
                titleText: "Informations légales",
                type: "success",
                width: 900,
                html: "<div style='font-size: 14px'>Safetee.io est la propriété de la société Tech Exec, Société par Actions Simplifiée au capital de 1000 euros, inscrite au registre du commerce d'Amiens sous le numéro 827 927 708.<br><br>" +
                "Le siège social de la société est situé au 54 grande avenue, 60260 Lamorlaye - FRANCE<br>contact@techexec.fr.<br><br>" +
                    "Le responsable de la publication du site Web est Philippe Bridant, en qualité de représentant légal de la société Tech Exec.<br><br>" +
                    "L’ensemble de ce site relève de la législation française sur le droit d’auteur et plus largement sur la propriété intellectuelle. Tous les droits de reproduction, de représentation et de communication publique sont réservés, y compris pour les documents téléchargeables et représentations visuelles, audiovisuelles, photographiques, iconographies ou autres.<br><br>" +
                    "La reproduction de tout ou partie de ce site sur un support électronique, quel qu’il soit, est formellement interdite sauf autorisation expresse du directeur de la publication.<br><br>" +
                    "Il est expressément entendu que tout litige lié à l’utilisation du site sera soumis à la loi française<br><br>" +
                    "www.safetee.io n'utilise pas de cookies.<br><br>" +
                "Hébergement<br>" +
                "Scaleway - 8 rue de la Ville l’Evêque, 75008 Paris<br>" +
                    "Directeur de publication : Arnaud Brindejonc de Bermingham<br>" +
                    "Site internet : https://www.scaleway.com/fr/mentions-legales/</div>",
            });

        },
        contactClicked() {

            let swal = this.$swal({
                titleText: "Contact",
                type: "success",
                  input: 'email',
                inputPlaceholder: 'Votre adresse email',
                html: "<div style='font-size: 16px'>Veuillez renseigner votre adresse email ci dessous et nous prendrons contact avec vous rapidement</div>",
                showCancelButton: true,
                confirmButtonText: 'Envoyer',
                cancelButtonText: 'Fermer',
                closeOnConfirm: false,
                closeOnCancel: false
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post("/api/public/requestaccess/",
                        { email: result.value}).then((response) => {

                            let swal = this.$swal({
                                titleText: "Demande envoyée",
                                icon: "success",
                                type: "success",
                                text: 'Nous vous remercions pour votre intêret pour Safetee. Notre équipe vous contactera rapidement.',
                                showCloseButton: true,
                                closeButtonText: "close",
                            });
                            this.email = null;

                        }).catch((xhr_error) => {

                            let code = xhr_error.request && xhr_error.request.status || null;
                            if (code == 400) {
                                this.error = xhr_error.response.data.email[0];
                            } else {
                                let swal = this.$swal({
                                    icon: "error",
                                    titleText: "Une erreur est survenue",
                                    type: "error",
                                    text: "Nous n'avons pas réussi communiquer votre adresse email à nos équipes pour qu'ils vous recontactent. Nous vous remercions de bien vouloir essayer de nouveau utlterieurement.",
                                    showCloseButton: true,
                                });
                            }

                        });
                }
            });
        },
    }),
    mounted() {
        document.body
            .querySelector(".safeteeview")
            .addEventListener("scroll", function () {
                AOS.refresh();
            });
    },
};
</script>

<style lang="scss">
.homeconnect {
    background-color: white;
    display: flex;
    flex-direction: column;
}
.section__title {
    font-family: InterstatePlus;
    font-size: 34px;
    font-weight: bold;
}

p {
    font-family: Avenir Book;
    font-size: 16px;
    font-weight: lighter;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #6b6b6b;

    .bold {
        font-weight: normal;
        color: #202020;

        background-image: linear-gradient(
            to right,
            rgba($color-secondary, 0.4) 75%
        );
        background-position: 0 0.74em;
        background-repeat: repeat-x;
        background-size: 17px 10px;
    }
}

.text-link {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $color-primary;
}

section {
    background-color: white;
    &.section--blue {
        background-color: $color-primary-lighter;
    }
}

.homesubtitle {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: -0.2px;
    text-align: center;
    color: #202020;
}

header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 75px;
    padding: 18px 30px;
    box-sizing: border-box;
    background-color: white;

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-image: linear-gradient(
            to left,
            rgba(246, 246, 246, 0) 0%,
            rgba(87, 154, 255, 0.2) 50%,
            rgba(234, 234, 234, 0) 100%
        );
    }
}

.safetee-logo {
    margin-left: 30px;

    .logo-secondary {
        display: none;
    }

    &:hover {
        .logo {
            display: none;
        }

        .logo-secondary {
            display: block;
        }
    }
}

.header__links {
    display: flex;
    margin-left: auto;
    align-items: center;

    a:first-of-type {
        margin-right: 30px;
    }
}

.head-section {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 550px;
    background-color: white;
}

h2.head-section__title {
    margin-top: 10px;
}
.head-section__text {
    margin-bottom: 20px;
    &:before {
        content: "";
    }
}

.head-section__description {
    margin: 15px 0 40px 0;
}

.head-section__buttons {
    display: flex;
    justify-content: space-between;

    .buttoncomp {
        height: 50px;
        padding: 15px 38px;
    }
}

.moreinfo-button {
    margin-left: 20px;
}

section {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 20px;
}

.mission-section {
    position: relative;
    flex-direction: column;
    justify-content: center;
    padding: 50px 20px;
}

.halfcircle {
    height: 300px;
    width: 300px;
    position: absolute;
    top: -150px;
    right: 0;
    overflow: hidden;

    &:before {
        position: relative;
        width: 100%;
        height: 100%;
        content: "";
        transform: translate(50%, 0%);
        display: block;
        border-radius: 50%;
        background-color: $color-primary-light;
    }
}

.section__title {
    margin: 0 auto;
}

.mission-section__subtitle {
    margin: 15px auto 50px auto;
}

.mission-section__cards-list {
    display: flex;
    justify-content: space-between;
}

.mission-section__card {
    height: 100%;
    width: 100%;
    padding: 30px;
    border-radius: 7px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 7px 0 rgba(0, 0, 0, 0.05);
    background-color: white;

    p {
        margin-top: 10px;
    }
}

.mission-section__card-title {
    margin-top: 20px;
}

.mission-section__card-icon {
    height: 38px;
}

.section_content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: auto;
    padding: 0 30px;
}

.section-step__content {
    width: 100%;
}

.section-step {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 75px 0 60px 0;

    &:nth-child(odd) {
        flex-direction: row-reverse;
    }

    & + & {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 2px;
            background-image: linear-gradient(
                to left,
                rgba(246, 246, 246, 0) 0%,
                rgba(87, 154, 255, 0.2) 50%,
                rgba(234, 234, 234, 0) 100%
            );
        }
    }
}

.feature-list__item {
    border-radius: 4px;
    padding: 12px 17px;
    box-shadow: 0 0 8px 0 rgba(18, 54, 109, 0.15);

    & + & {
        margin-top: 15px;
    }

    & > * {
        padding-left: 50px;
    }
    &:before {
        content: attr(icon) " ";
        position: absolute;
        float: left;
        height: 100%;
        width: 20px;
        font-size: 26px;
        margin-right: 20px;
    }
}

.feature-list-item__title {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #202020;
}

.feature-list-item__text {
    display: inline-block;
    font-family: Avenir;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #6b6b6b;
}

.cta-strip {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    background-size: cover;
    background-position: center;
    background-image: url("~@/assets/illustration/bg-push.svg");
}

.cta-strip__title {
    font-family: InterstatePlus;
    font-size: 32px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.4px;
    text-align: center;
    color: #ffffff;
}

.cta-strip__subtitle {
    margin: auto;
    max-width: 500px;
    margin-top: 4px;
    font-family: Avenir;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.2px;
    text-align: center;
    color: #ffffff;
}

.cta-strip__list {
    display: flex;
    justify-content: center;
    color: $color-white;

    & > * {
        margin: 30px 10px 0 10px;

        svg {
            margin-top: -3px;
            height: 10px;
            width: 12px;
            fill: $color-secondary;
        }
    }
}

.rgpd {
    display: flex;
    max-width: 1000px;
    margin: 90px auto;
    align-items: center;
    justify-content: center;
}

.rgpd__title {
    font-family: InterstatePlus;
    font-size: 32px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.4px;
    color: #202020;
}

.rgpd__text {
    margin-top: 10px;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.2px;
    color: #6b6b6b;
}

.rgpd__image {
    height: 165px;
    width: 240px;

    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("~@/assets/illustration/rgpd.svg");
}

footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: $color-primary-dark;
    nav ul {
        display: flex;
        margin: 0;
    }

    color: $color-white;
    a,
    a:hover {
        color: $color-white;
    }
}

.rgpd__text-wrapper {
    width: 50%;
}

@media (max-width: 768px) {
    section {
        padding: 20px;

        .illustration {
            display: none;
        }
    }

    .section_content {
        padding: 0 0;
    }

    .section-step {
        padding: 40px 0 40px 0;
    }

    .section__title {
        text-align: center;
        margin: 40px auto;
    }

    .rgpd__text-wrapper {
        width: 100%;
    }

    .mission-section:after {
        content: none;
    }

    .head-section__text {
        width: 100%;
        text-align: justify;
        flex-direction: column;
        justify-content: center;

        br {
            display: none;
        }
    }

    .head-section__buttons {
        flex-direction: column;

        .buttoncomp {
            width: 100%;
            margin: 20px 0 0 0;
        }
    }

    .cta-strip {
    }

    .cta-strip__title {
        font-size: 30px;
    }

    .cta-strip__list li {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: auto;
    }

    .rgpd {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 40px auto 40px auto;

        &__title {
            text-align: start;
        }

        .rgpd__image {
            min-height: 30vh;
            margin-left: 0;
            background-size: contain;
            background-position: center;
        }
    }
}

.buttoncomp {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;

    min-height: 35px;
    width: auto;

    font-size: 14px;
    letter-spacing: -0.4px;
    font-weight: 900;
    white-space: nowrap;

    appearance: none;
    border-radius: 3px;
    color: $color-white;
    border: none;
    transition: 0.3s background-color, 0.3s color, 0.3s border-color;
    background-color: $color-primary;

    &:hover,
    &:active,
    &.hover {
        text-decoration: none;
        color: $color-white;
        background-color: $color-secondary;
    }

    &.ghost {
        color: $color-primary;
        border: 1px solid currentColor;
        background-color: $color-white;

        &:hover,
        &.hover,
        &:active {
            color: $color-secondary;
        }
    }

    &.secondary {
        color: $color-white;
        background-color: $color-secondary;

        &.ghost {
            color: $color-secondary;
            background-color: $color-white;
            &:hover,
            &.hover {
                color: $color-primary;
            }

            &.disabled,
            &[disabled] {
                background-color: $color-white;
            }
        }
    }

    min-width: 125px;

    &.huge {
        padding: 20px 30px;
    }

    &.large {
        width: 100%;
        max-width: 100%;
    }

    &.small {
        min-width: 125px;
        min-height: 30px;
        border-radius: 3px;

        font-family: Avenir;
        font-size: 12px;
        font-weight: 900;

        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
    }

    &.fullwidth {
        width: 100%;
    }

    :global(svg),
    :global(span) {
        margin: 0 23px;
    }

    :global(svg) {
        margin-right: 10px;

        fill: currentColor;
    }
}
</style>
