import Vue from "vue";
import VueI18n from "vue-i18n";
import en_US from "@/locales/en_US";

Vue.use(VueI18n);

const messages = {
  en_US,
};

function loadLocaleMessages() {
  const lang = navigator.language.replace("-", "_");

  const locales = require.context("../locales", true, /[A-Za-z0-9-_,\s]+$/i);
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\/index.js/i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });

  return messages;
}

export default new VueI18n({
  locale: navigator.language.replace("-", "_"),
  fallbackLocale: "en_US",
  messages: loadLocaleMessages(),
});
