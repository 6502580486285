<template>
    <MagnifierSvg v-if="use == 'magnifier'" />
    <FilterSvg v-else-if="use == 'filter'" />
    <HideSvg v-else-if="use == 'hide'" />
    <MenuSvg v-else-if="use == 'menu'" />
    <ArrowSvg v-else-if="use == 'arrow'" />
    <CrossSvg v-else-if="use == 'cross'" />
    <SmartPhone v-else-if="use == 'smartphone'" />
    <Telephone v-else-if="use == 'telephone'" />
    <Company v-else-if="use == 'company'" />
    <City v-else-if="use == 'city'" />
    <Address v-else-if="use == 'address'" />
    <Logo v-else-if="use == 'logo'" />
    <LogoNegative v-else-if="use == 'logo-negative'" />
    <LogoSecondary v-else-if="use == 'logo-secondary'" />
    <DashboardLogo v-else-if="use == 'logo-dashboard'" />
    <Chat v-else-if="use == 'chat'" />
    <Chat2 v-else-if="use == 'chat2'" />
    <Dashboard v-else-if="use == 'dashboard'" />
    <Virus v-else-if="use == 'virus'" />
    <Virus2 v-else-if="use == 'virus2'" />
    <Shield v-else-if="use == 'shield'" />
    <Shield2 v-else-if="use == 'shield2'" />
    <Settings v-else-if="use == 'settings'" />
    <ChevronR v-else-if="use === 'chevron-right'" />
    <ChevronB v-else-if="use === 'chevron-bottom'" />
    <Employee v-else-if="use === 'employee'" />
    <Configuration v-else-if="use === 'configuration'" />
    <Connection v-else-if="use === 'connection'" />
    <Stats v-else-if="use === 'stats'" />
    <BackArrow v-else-if="use === 'back-arrow'" />
    <Check v-else-if="use === 'check'" />
    <Checking v-else-if="use === 'checking'" />
    <Search v-else-if="use === 'search'" />
    <Signature v-else-if="use === 'signature'" />
    <Document v-else-if="use === 'document'" />
    <Bell v-else-if="use === 'bell'" />
    <Pen v-else-if="use === 'pen'" />
    <Bin v-else-if="use === 'bin'" />
    <Puzzle v-else-if="use === 'puzzle'" />
    <Group v-else-if="use === 'group'" />
    <Synchro v-else-if="use === 'synchro'" />
    <List v-else-if="use === 'list'" />
    <Download v-else-if="use === 'download'" />
    <Import v-else-if="use === 'import'" />
    <Cloud v-else-if="use === 'cloud'" />
    <Tools v-else-if="use === 'tools'" />
    <Interface v-else-if="use === 'interface'" />

    <Illu01 v-else-if="use == 'illu-01'" />
    <Illu02 v-else-if="use == 'illu-02'" />
    <Illu03 v-else-if="use == 'illu-03'" />
    <Illu04 v-else-if="use == 'illu-04'" />
    <IlluSignup v-else-if="use == 'illu-signup'" />
</template>

<script>
import MagnifierSvg from '@/assets/svg/common/magnifier.svg?inline';
import FilterSvg from '@/assets/svg/common/filter.svg?inline';
import HideSvg from '@/assets/svg/common/hide.svg?inline';
import MenuSvg from '@/assets/svg/common/menu.svg?inline';
import ArrowSvg from '@/assets/svg/common/arrow.svg?inline';
import CrossSvg from '@/assets/svg/common/cross.svg?inline';
import SmartPhone from '@/assets/svg/common/smartphone.svg?inline';
import Telephone from '@/assets/svg/common/telephone.svg?inline';
import Company from '@/assets/svg/common/company.svg?inline';
import City from '@/assets/svg/common/city.svg?inline';
import Address from '@/assets/svg/common/address.svg?inline';
import Logo from '@/assets/svg/common/logo.svg?inline';
import LogoNegative from '@/assets/svg/common/logo-negative.svg?inline';
import LogoSecondary from '@/assets/svg/common/logo-secondary.svg?inline';
import DashboardLogo from '@/assets/svg/common/logo-dashboard.svg?inline';
import Chat from '@/assets/svg/common/chat.svg?inline';
import Chat2 from '@/assets/svg/common/chat2.svg?inline';
import Dashboard from '@/assets/svg/common/dashboard.svg?inline';
import Virus from '@/assets/svg/common/virus.svg?inline';
import Virus2 from '@/assets/svg/common/virus2.svg?inline';
import Shield from '@/assets/svg/common/shield.svg?inline';
import Shield2 from '@/assets/svg/common/shield2.svg?inline';
import Settings from '@/assets/svg/common/settings.svg?inline';
import Employee from '@/assets/svg/common/employee.svg?inline';
import ChevronR from '@/assets/svg/common/chevron-right.svg?inline';
import ChevronB from '@/assets/svg/common/chevron-bottom.svg?inline';
import Configuration from '@/assets/svg/common/configuration.svg?inline';
import Stats from '@/assets/svg/common/stats.svg?inline';
import BackArrow from '@/assets/svg/common/back-arrow.svg?inline';
import Check from '@/assets/svg/common/check.svg?inline';
import Checking from '@/assets/svg/common/checking.svg?inline';
import Search from '@/assets/svg/common/search.svg?inline';
import Signature from '@/assets/svg/common/signature.svg?inline';
import Document from '@/assets/svg/common/document.svg?inline';
import Bell from '@/assets/svg/common/bell.svg?inline';
import Pen from '@/assets/svg/common/pen.svg?inline';
import Bin from '@/assets/svg/common/bin.svg?inline';
import Puzzle from '@/assets/svg/common/puzzle.svg?inline';
import Group from '@/assets/svg/common/group.svg?inline';
import Synchro from '@/assets/svg/common/synchro.svg?inline';
import List from '@/assets/svg/common/list.svg?inline';
import Download from '@/assets/svg/common/download.svg?inline';
import Import from '@/assets/svg/common/import.svg?inline';
import Interface from '@/assets/svg/common/interface.svg?inline';
import Tools from '@/assets/svg/common/tools.svg?inline';
import Cloud from '@/assets/svg/common/cloud.svg?inline';

import Illu01 from '@/assets/illustration/illu-01.svg?inline';
import Illu02 from '@/assets/illustration/illu-02.svg?inline';
import Illu03 from '@/assets/illustration/illu-03.svg?inline';
import Illu04 from '@/assets/illustration/illu-04.svg?inline';
import IlluSignup from '@/assets/illustration/illu-signup.svg?inline';
import Connection from '@/assets/svg/common/illu-connexion.svg?inline';

export default {
    components: {
        Cloud,
        Interface,
        Tools,
        Download,
        Group,
        Puzzle,
        Chat2,
        MagnifierSvg,
        CrossSvg,
        FilterSvg,
        HideSvg,
        MenuSvg,
        ArrowSvg,
        SmartPhone,
        Telephone,
        Company,
        City,
        Address,
        Logo,
        LogoNegative,
        LogoSecondary,
        DashboardLogo,
        Chat,
        Dashboard,
        Virus,
        Virus2,
        Shield,
        Shield2,
        Settings,
        ChevronR,
        ChevronB,
        Configuration,
        Stats,
        Connection,
        Employee,
        BackArrow,
        Checking,
        Check,
        Search,
        Signature,
        Document,
        Bell,
        Pen,
        Bin,
        Synchro,
        List,
        Import,

        Illu01,
        Illu02,
        Illu03,
        Illu04,
        IlluSignup,
    },
    props: {
        use: {
            type: String,
            required: true,
            default: null,
        },
    },
};
</script>
