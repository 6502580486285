import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './init-pluggins';
import PublicRouter from '@/routers/public';
import PublicApp from './apps/public.vue';
import store from '@/stores/store';
import i18n from './plugins/i18n'

import AOS from 'aos'
import 'aos/dist/aos.css'

let router = PublicRouter;
let app = PublicApp;

new Vue({
    created () {
        AOS.init()
    },
    router,
    store,
    i18n,
    render: h => h(app)
}).$mount('#app');
