export default {
    install(Vue) {
        Vue.$main_style = {};

        Vue.mixin({
            created() {
                this.init_css();
            },
            methods: {
                init_css() {
                    if (!this.$style) {
                        this.$style = {};
                    }
                    Object.keys(Vue.$main_style).forEach((key) => {
                        this.$style[key] = Vue.$main_style[key];
                    });
                },
            },
        });

        Vue.prototype.$setMainStyle = function setMainStyle(mainStyle) {
            Vue.$main_style = mainStyle;
        };
    },
};
