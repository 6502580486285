import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/home.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    name: 'public',
    base: process.env.BASE_URL,
    routes: [
        {
            name: 'home',
            path: '/',
            label: 'Welcome',
            component: Home,
        },
        {
            name: 'legal',
            path: '/legal/',
            label: 'Legal',
            component: Home,
        },
        {
            name: 'contact',
            path: '/contact',
            label: 'Contact',
            component: Home,
        },

    ],
});
