import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const modules = {
};

const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
};

export default new Vuex.Store({
    modules,
    state,
    getters,
    mutations,
    actions,
});
