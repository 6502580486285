import { library } from '@fortawesome/fontawesome-svg-core'
import { faCogs, faEnvelope, faUsers, faUserPlus, faVial, faClone, faCopy, faUserCircle, faPlusSquare, faPhone, faMapMarkerAlt, faBuilding, faMobileAlt, faPlus, faBan, faCheck, faEdit, faUndo, faTimesCircle, faTrashAlt, faFastBackward, faSignInAlt, faDolly, faTruck, faFilePdf, faCheckCircle, faShippingFast, faBox, faBoxes, faClock, faCalendarAlt, faBoxOpen, faPlusCircle, faPeopleCarry, faChild} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
    install(Vue) {

        library.add(faUsers);
        library.add(faCogs);
        library.add(faEnvelope);
        library.add(faUserPlus);
        library.add(faVial);
        library.add(faClone);
        library.add(faCopy);
        library.add(faPlusSquare);
        library.add(faPhone);
        library.add(faUserCircle);
        library.add(faBuilding);
        library.add(faMapMarkerAlt);
        library.add(faMobileAlt);
        library.add(faCheck);
        library.add(faClock);
        library.add(faCalendarAlt);
        library.add(faBoxes);
        library.add(faBan);
        library.add(faFilePdf);
        library.add(faCheckCircle);
        library.add(faShippingFast);
        library.add(faBoxOpen);
        library.add(faBox);
        library.add(faPlusCircle);
        library.add(faPeopleCarry);
        library.add(faChild);
        library.add(faDolly);
        library.add(faTimesCircle);
        library.add(faSignInAlt);
        library.add(faTrashAlt);
        library.add(faFastBackward);
        library.add(faUndo);
        library.add(faTruck);
        library.add(faEdit);
        library.add(faPlus);

        Vue.component('font-awesome-icon', FontAwesomeIcon);
    },
};
