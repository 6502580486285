import Vue from 'vue';
import VueMq from 'vue-mq';

import InitCssComponent from '@/plugins/plugin-init-css-component';
import InitFontAwesome from '@/plugins/plugin-init-fontawesome';
import InitComponents from '@/plugins/plugin-init-components';
import VueScrollTo from 'vue-scrollto';
import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueScrollTo);
Vue.use(VueSweetalert2);

// You can also pass in the default options
Vue.use(VueScrollTo, {
     container: ".safeteeview",
     duration: 500,
     easing: "ease",
     offset: 0,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
})

Vue.use(VueMq, {
    breakpoints: {
        sm: 576,
        md: 768,
        lg: 992,
        xl: Infinity,
    },
});

Vue.use(InitCssComponent);
Vue.use(InitFontAwesome);
Vue.use(InitComponents);
