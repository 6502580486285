<template>
    <section class="cta-strip register">
        <div class="section_content w-100">
            <div class="row">
                <div class="col">
                    <div class="cta-strip__title">
                        Créer un compte gratuitement
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="cta-strip__subtitle mt-1">
                        Créez votre compte dès maintenant et profitez de Safetee sans limitation.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="cta-strip__form w-100 justify-content-center">
			<a href="https://dashboard.safetee.io/register/">
				<button class="mt-4" type="submit">Créer un compte gratuitement</button>
			</a>
                    </div>
                </div>
            </div>
            <ul class="d-block d-sm-flex cta-strip__list">
                <li class="d-flex d-sm-block align-items-center">
                    <MasterSvg use="check" class="mr-2 mr-sm-0 mb-0" />
                    Sans engagement
                </li>
                <li class="d-flex d-sm-block align-items-center">
                    <MasterSvg use="check" class="mr-2 mr-sm-0 mb-0" />
                    CB non requise
                </li>
            </ul>
        </div>
    </section>
</template>

<script>

import Vue from 'vue';
import axios from 'axios';
import { mapActions } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import MasterSvg from '@/components/master-svg.vue';

Vue.component('my-component', {
  template: '<div>A custom component!</div>'
})

export default {
    components: {
        MasterSvg,
    },
    data() {
        return { email: "", isMobile, error: null};
    },
    watch: {
        email() {
            this.error = null;
        },
    },
    methods: {
        start() {
            axios.post("/api/public/requestaccess/",
                { email: this.email}).then((response) => {

                    let swal = this.$swal({
                        titleText: "Demande envoyée",
                        icon: "success",
                        type: "success",
                        text: 'Nous vous remercions pour votre intêret pour Safetee. Notre équipe vous contactera rapidement.',
                        showCloseButton: true,
                        closeButtonText: "close",
                    });
                    this.email = null;

            }).catch((xhr_error) => {

                let code = xhr_error.request && xhr_error.request.status || null;
                if (code == 400) {
                    this.error = xhr_error.response.data.email[0];
                } else {
                    let swal = this.$swal({
                        icon: "error",
                        titleText: "Une erreur est survenue",
                        type: "error",
                        text: "Nous n'avons pas réussi communiquer votre adresse email à nos équipes pour qu'ils vous recontactent. Nous vous remercions de bien vouloir essayer de nouveau utlterieurement.",
                        showCloseButton: true,
                    });
                }

            });

        },
    },
    mounted() {
    }
};

</script>

<style lang="scss">

.cta-strip.register {
    .error {
        text-align: center;
        margin-top: 20px;
        font-size: 12pt;
        font-weight: bold;
        color: #ec6161;
        height: 20px;
    }
}

.cta-strip__form {
    display: flex;
    margin: 10px auto 0 auto;
}

.cta-strip__form input {
    height: 40px;
    width: 380px;
    padding: 0 10px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: none;
}
.cta-strip__form button {
    display: inline-block;
    vertical-align: top;
    height: 50px;
    width: 280px;
    border: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    color: $color-white;
    background-color: $color-secondary;
}

@media (max-width: 768px) {
    .cta-strip__form { 
        flex-direction: column;
        align-items: center;
    }
    .cta-strip__form button {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .cta-strip__form input {
        text-align: center;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        margin-bottom: 20px;
        width: 100%;
    }
}

</style>
